<template>
    <div class="list-box">
        <div class="info-step-header">
            <el-breadcrumb>
                <el-breadcrumb-item>展位</el-breadcrumb-item>
                <el-breadcrumb-item>推广管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="big-title">
            <div>所有推广计划</div>
            <div class="money">
                推广余额：
                <span>&yen;</span>
                {{balance}}
            </div>
        </div>
        <div class="main-box">
            <div class="tab-box">
                <div class="item">推广计划</div>
            </div>
            <div class="operate-box">
                <el-button class="btn-red" @click="addBtn" :disabled="infoList.length > 0">新建图片计划</el-button>
<!--                <el-button class="btn-red-line" @click="delBtn">删除</el-button>-->
                <el-button class="btn-red-line" @click="endBtn" :disabled="endNumber === 0">结束推广</el-button>
                <div v-if="isExamOrTrain" class="end-number" :class="endNumber === 0 ? 'disabled' : ''">结束推广剩余次数：<span>{{endNumber}}</span></div>
            </div>
            <el-table :data="infoList" border style="width: 100%; margin-top: 20px; flex: 1" height="1%"
                      :cell-style="{height: '60px',color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}"
                      :header-cell-style="{fontWeight: 'normal', color: '#14141C', background: '#F5F5F5',height: '60px'}">
                <el-table-column label="推广计划" width="220">
                    <template slot-scope="scope">
                        <div v-if="idEditName !== scope.row.op_promote_id" class="blue showEdit-icon" @click="openUnitInput(scope.row, scope.$index)">
                            {{scope.row.unit_name}} <span class="iconfont">&#xe616;</span>
                        </div>
                        <el-input v-show="idEditName === scope.row.op_promote_id" size="medium"
                                  :ref="`unitName_${scope.$index}`"
                                  v-model="scope.row.unit_name"
                                  maxlength="50"
                                  @blur="changeUnitName(scope.row, scope.$index)"></el-input>

                    </template>
                </el-table-column>
                <el-table-column prop="discount" label="时段 | 实时折扣系数" width="150">
                    <template slot-scope="scope">
                        <div class="blue" @click="changeTime(scope.row.op_promote_id)">{{scope.row.time}}% | {{scope.row.discount}}%</div>
                    </template>
                </el-table-column>
                <el-table-column label="日预算">
                    <template slot-scope="scope">
                        <div class="blue" @click="changeBudget(scope.row)">{{Number(scope.row.budget) ? Number(scope.row.budget) : '不限'}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="展现数">
                    <template slot-scope="scope">
                        {{scope.row.show_num === null ? '-' : scope.row.show_num}}
                    </template>
                </el-table-column>
                <el-table-column label="转化数">
                    <template slot-scope="scope">
                        {{scope.row.conversion_num === null ? '-' : scope.row.conversion_num}}
                    </template>
                </el-table-column>
                <el-table-column prop="click_rate" label="点击率（%）"></el-table-column>
                <el-table-column prop="conversion_rate" label="转化率（%）"></el-table-column>
                <el-table-column prop="total_fee" label="总费用"></el-table-column>
                <el-table-column label="操作" width="150px" fixed="right">
                    <template slot-scope="scope">
                        <el-link :underline="false" @click="editBtn(scope.row.op_promote_id)" type="success">编辑</el-link>
                        <el-link :underline="false" @click="delBtn(scope.row.id)" type="danger">删除</el-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!--        自定义预算-->
        <CustomBudgetDrawer :ruleForm.sync="ruleForm" ref="customBudgetDrawer" :pageType="pageType" @getList="getList" />

        <!--        时段设置-->
        <CustomTimeDrawer :ruleForm.sync="ruleForm" ref="customTimeDrawer" :pageType="pageType" @getList="getList" />
    </div>
</template>

<script>
    import { StuOpProGetBalance, StuOpProGetPlanList, StuOpProEditPromote, StuOpProInfo, StuOpProDel, StuOpProEnd, StuOpProGetEndNumber } from '@/utils/apis.js'

    import CustomBudgetDrawer from '@/components/student/exam/infographicPromotion/CustomBudgetDrawer'
    import CustomTimeDrawer from '@/components/student/exam/infographicPromotion/CustomTimeDrawer'
    export default {
        components: {
            CustomBudgetDrawer,
            CustomTimeDrawer,
        },
        data() {
            return {
                balance: null,
                infoList: [],
                idEditName: null,
                ruleForm: {
                    id: null,
                    budget: void 0, // 预算值
                    show_type: 1, // 时间段设置 1、整天展示 2自定义展示
                    // 时间段设置
                    time_frame_data: [
                        {
                            index: 1, // 时间 1 ：0-1 、 2：1-2、3：2-3
                            type: 1, // 0：不投放 1：无折扣 2：自定义
                            percent: 100, // 比例
                            timezone : '00:00-01:00',
                        },
                        {
                            index: 2,
                            type: 1,
                            percent: 100,
                            timezone : '01:00-02:00',
                        },
                        {
                            index: 3,
                            type: 1,
                            percent: 100,
                            timezone : '02:00-03:00',
                        },
                        {
                            index: 4,
                            type: 1,
                            percent: 100,
                            timezone : '03:00-04:00',
                        },
                        {
                            index: 5,
                            type: 1,
                            percent: 100,
                            timezone : '04:00-05:00',
                        },
                        {
                            index: 6,
                            type: 1,
                            percent: 100,
                            timezone: '05:00-06:00',
                        },
                        {
                            index: 7,
                            type: 1,
                            percent: 100,
                            timezone: '06:00-07:00',
                        },
                        {
                            index: 8,
                            type: 1,
                            percent: 100,
                            timezone: '07:00-08:00',
                        },
                        {
                            index: 9,
                            type: 1,
                            percent: 100,
                            timezone: '08:00-09:00',
                        },
                        {
                            index: 10,
                            type: 1,
                            percent: 100,
                            timezone: '09:00-10:00',
                        },
                        {
                            index: 11,
                            type: 1,
                            percent: 100,
                            timezone: '10:00-11:00',
                        },
                        {
                            index: 12,
                            type: 1,
                            percent: 100,
                            timezone: '11:00-12:00',
                        },
                        {
                            index: 13,
                            type: 1,
                            percent: 100,
                            timezone: '12:00-13:00',
                        },
                        {
                            index: 14,
                            type: 1,
                            percent: 100,
                            timezone: '13:00-14:00',
                        },
                        {
                            index: 15,
                            type: 1,
                            percent: 100,
                            timezone: '14:00-15:00',
                        },
                        {
                            index: 16,
                            type: 1,
                            percent: 100,
                            timezone: '15:00-16:00',
                        },
                        {
                            index: 17,
                            type: 1,
                            percent: 100,
                            timezone: '16:00-17:00',
                        },
                        {
                            index: 18,
                            type: 1,
                            percent: 100,
                            timezone: '17:00-18:00',
                        },
                        {
                            index: 19,
                            type: 1,
                            percent: 100,
                            timezone: '18:00-19:00',
                        },
                        {
                            index: 20,
                            type: 1,
                            percent: 100,
                            timezone: '19:00-20:00',
                        },
                        {
                            index: 21,
                            type: 1,
                            percent: 100,
                            timezone: '20:00-21:00',
                        },
                        {
                            index: 22,
                            type: 1,
                            percent: 100,
                            timezone: '21:00-22:00',
                        },
                        {
                            index: 23,
                            type: 1,
                            percent: 100,
                            timezone: '22:00-23:00',
                        },
                        {
                            index: 24,
                            type: 1,
                            percent: 100,
                            timezone: '23:00-24:00',
                        },
                    ],
                },
                pageType: 'list',
                endNumber: null,
                isExamOrTrain: localStorage.getItem('examId') ? localStorage.getItem('examId') : null
            }
        },
        mounted() {
            this.getList()
            this.getBalance()
            if (this.isExamOrTrain) {
                this.getEndNumber()
            }
            // localStorage.removeItem('infoStepTwo')
        },
        methods: {
            // 获取推广次数
            getEndNumber() {
                StuOpProGetEndNumber().then((res) => {
                    this.endNumber = Number(res.data)
                }).catch((err) => {})
            },
            // 获取列表
            getList() {
                let params = {
                    op_id: this.$route.query.op_id, // 实操题试题id
                    course_id: this.$route.query.course_id, // 课程id 训练才有
                }
                StuOpProGetPlanList(params).then((res) => {
                    this.infoList = res.data
                }).catch((err) => {})
            },
            // 获取余额
            getBalance() {
                StuOpProGetBalance(this.$route.query.op_id).then((res) => {
                    this.balance = Number(res.data.balance)
                    // this.initTarget=Number(res.data.promote_fee)
                }).catch((err) => {})
            },
            // 修改推广计划名称
            openUnitInput(row, index) {
                this.idEditName = row.op_promote_id
                setTimeout(()=> {
                    this.$refs[`unitName_${index}`].focus()
                },1000);
            },
            // 修改推广计划名称
            changeUnitName(row, index) {
                if (row.unit_name === '') {
                    return this.$message.warning('请输入推广计划')
                }
                let params = {
                    id: row.op_promote_id,
                    unit_name: row.unit_name,
                }
                StuOpProEditPromote(params).then((res) => {
                    this.$message.success(res.msg)
                    this.getList()
                }).catch((err) => {})
                this.idEditName = null
            },
            // 新建图片计划
            addBtn() {
                this.$router.push({
                    path: '/student/infographicPromotion/stepOne',
                    query: {
                        op_id: this.$route.query.op_id,
                        course_id: this.$route.query.course_id,
                    }
                })
            },
            // 结束推广
            endBtn() {
                if (this.infoList.length == 0){
                  return this.$message.info('请先新建推广计划！')
                }
                this.$confirm(`您确定结束推广吗？`,'操作确认',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    cancelButtonClass: 'btn-red-line',
                    confirmButtonClass: 'btn-red',
                    type: 'warning'
                }).then(()=>{
                    let params = {
                        id: this.infoList[0].id,
                    }
                    if (!this.isExamOrTrain) {
                        params.op_id = this.$route.query.op_id
                        params.course_id = this.$route.query.course_id
                    }
                    StuOpProEnd(params).then((res) => {
                        this.$message.success(res.msg)
                        this.getList()
                        this.getBalance()
                        if (this.isExamOrTrain) {
                            this.getEndNumber()
                        }
                    }).catch((err) => {})
                }).catch(()=>{
                  this.$message.info('已取消结束推广')
                })
            },
            // 编辑
            editBtn(id) {
                this.$router.push({
                    path: '/student/infographicPromotion/stepOne',
                    query: {
                        op_id: this.$route.query.op_id,
                        course_id: this.$route.query.course_id,
                        editId: id
                    }
                })
            },
            // 删除
            delBtn(id) {
                this.$confirm(`您确定要删除该计划吗？`,'操作确认', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    cancelButtonClass: 'btn-red-line',
                    confirmButtonClass: 'btn-red',
                    type: 'warning'
                }).then(() => {
                    StuOpProDel(id).then((res) => {
                        this.$message.success(res.msg)
                        this.getList()
                        this.getBalance()
                        if (this.isExamOrTrain) {
                            this.getEndNumber()
                        }
                        localStorage.removeItem('infoStepTwo')
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                })
            },
            // 修改日预算
            changeBudget(row) {
                this.ruleForm.id = row.op_promote_id
                this.ruleForm.budget = Number(row.budget) ? Number(row.budget) : void 0
                this.$refs.customBudgetDrawer.customBudgetDrawer = true
            },
            // 修改时段 实时折扣系数
            changeTime(id) {
                StuOpProInfo(id).then((res) => {
                    this.ruleForm.id = id
                    this.ruleForm.show_type = res.data.show_type
                    this.ruleForm.time_frame_data = res.data.time_frame_data
                    this.$refs.customTimeDrawer.customTime = true
                })
            },
        },
    }
</script>

<style scoped lang="scss">
    .list-box {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 40px;
    }
    .info-step-header {
        padding: 22px 0 0;
    }
    .big-title {
        font-size: 20px;
        line-height: 1;
        margin: 20px 0;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        .money {
            font-size: 18px;
            color: #FD4446;
            span {
                font-size: 12px;
                margin-right: 2px;
            }
        }
    }
    .main-box {
        height: 1%;
        flex: 1;
        background: #fff;
        margin-bottom: 50px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        .tab-box {
            font-size: 20px;
            color: #FD4446;
            line-height: 1;
            .item {
                position: relative;
                display: inline-block;
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -10px;
                    height: 2px;
                    background: #FD4446;
                }

            }
        }
        .operate-box {
            margin-top: 30px;
            display: flex;
            .end-number {
                color: #95D476;
                line-height: 38px;
                background: #F0F9EB;
                border-radius: 4px;
                border: 1px solid #E3F4DB;
                margin-left: 10px;
                font-size: 16px;
                padding: 0 16px;
                span {
                    font-weight: 500;
                }
                &.disabled {
                    color: #9DA0A5;
                    background: #F4F4F5;
                    border: 1px solid #EEEFF0;
                }
            }
        }
    }
    .blue {
        color: #1E63F1;
        cursor: pointer;
    }
    .showEdit-icon {
        .iconfont {
            color: #333;
            font-size: 14px;
            margin-left: 10px;
            display: none;
        }
        &:hover {
            .iconfont {
                display: inline-block;
            }
        }
    }
</style>